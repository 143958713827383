<template>
    <div v-if="rerenderComponent">
        <AreYouSurePopup
            :content-text-prop="openWebsiteDialog"
            :show-dialog="showOpenWebsitePopup"
            ok-button-text="Öffnen"
            cancel-button-text="Abbrechen"
            :ok-button-icon="doneIcon"
            :header-color="'#343e55'"
            :usage="'app'"
            @cancel="showOpenWebsitePopup = false;"
            @ok="openUrl(urlToOpen)"
            @close="showOpenWebsitePopup = false"
        />
        <AreYouSurePopup
            :show-dialog="showAreYouSure"
            content-text-prop="Wollen Sie wirklich alle markierten Apps löschen?"
            ok-btn-color="frot"
            :header-color="'#343e55'"
            :usage="'app'"
            @close="showAreYouSure = false"
            @cancel="showAreYouSure = false"
            @ok="deleteSelectedApps"
        />
        <div class="widgetAlign">
            <div
                class="widgetContainer"
                style="background-color: white;"
                @click="clickWidgetBody"
            >
                <ClickableBadge
                    v-if="showMainApps"
                    :event-list="eventBadgeList"
                    :small-event-list="smallEventList"
                    @badgeClicked="appBadgeClicked"
                />
                <!-- Header -->
                <div class="header">
                    <div>
                        <div
                            style="max-width: 100%; display: flex; align-items: center; height: 35px"
                            class="mx-4"
                        >
                            <div
                                style="white-space: nowrap;"
                                class="pa-0 font-style d-flex align-center noselect"
                            >
                                <v-tooltip
                                    v-if="showMainApps && !showCreateExternalApp && !showAppPicker"
                                    top
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            aria-hidden="true"
                                            alt="Apps"
                                            class="iconToWhite mr-1"
                                            style="height: 20px"
                                            color="white"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="changeAppearance"
                                        >
                                            mdi-apps
                                        </v-icon>
                                    </template>
                                    <span>{{ 'Hier ' + (accountRole === 'pupil' ? 'kannst du' : 'können Sie') + ' auf verschiedene Apps zugreifen.' }}</span>
                                </v-tooltip>

                                <v-btn
                                    v-if="!showMainApps || showCreateExternalApp || showAppPicker"
                                    x-small
                                    icon
                                    dark
                                    style="height: 20px;cursor: pointer"
                                    @click="handleBackClick(); hasUserNewInternetApps();"
                                >
                                    <img
                                        :src="backIcon"
                                        class="icon20 iconToWhite"
                                        alt="zurück"
                                    >
                                </v-btn>
                                <v-tooltip
                                    v-if="showMainApps"
                                    top
                                    style="z-index: 9"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <h1
                                            tabindex="0"
                                            class="mb-0"
                                            style="font-size: larger"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="changeAppearance"
                                        >
                                            Apps
                                        </h1>
                                    </template>
                                    <span>{{ 'Hier ' + (accountRole === 'pupil' ? 'kannst du' : 'können Sie') + ' auf verschiedene Apps zugreifen.' }}</span>
                                </v-tooltip>
                                <p
                                    v-else
                                    tabindex="0"
                                    class="mb-0"
                                    style="font-size: larger; cursor: pointer"
                                    @click="handleBackClick()"
                                >
                                    Internet
                                </p>
                            </div>
                            <div
                                class="d-flex ml-4 flex-row-reverse align-center"
                                style="align-items: center; height: 35px; width:100%;max-width: 100%"
                            >
                                <v-tooltip
                                    v-if="(accountRole === 'teacher' || accountRole === 'maintainer') && !showCreateExternalApp && showMainApps && !showAppPicker"
                                    top
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            x-small
                                            text
                                            min-width="20"
                                            class="pa-0 ml-0"
                                            style="min-height: 30px; min-width: 30px"
                                            color="white"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="showCreateExternalApp = !showCreateExternalApp; selectedGroups = [];"
                                        >
                                            <img
                                                :src="plusIcon"
                                                class="iconToWhite"
                                                height="20"
                                                alt="Neue Externe Anwendung anlegen"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Neue Verlinkung erstellen</span>
                                </v-tooltip>
                                <!--                                <v-btn-->
                                <!--                                    v-if="(accountRole === 'teacher' || accountRole === 'maintainer')-->
                                <!--                                        && !showCreateExternalApp && !showAppPicker-->
                                <!--                                        && !isAppsEmpty"-->
                                <!--                                    x-small-->
                                <!--                                    text-->
                                <!--                                    min-width="20"-->
                                <!--                                    class="pa-0 ml-2"-->
                                <!--                                    style="height: 20px; width: 20px"-->
                                <!--                                    color="white"-->
                                <!--                                    @click="showAppPicker = !showAppPicker; selectedApps = []"-->
                                <!--                                >-->
                                <!--                                    <img-->
                                <!--                                        :src="trashIcon"-->
                                <!--                                        class="icon20 iconToWhite"-->
                                <!--                                        alt="Löschen"-->
                                <!--                                    >-->
                                <!--                                </v-btn>-->
                                <v-btn
                                    v-if="showAppPicker"
                                    text
                                    min-width="20"
                                    class="pa-0 ml-2"
                                    style="height: 20px; width: 20px"
                                    color="white"
                                    @click="toggleAreYouSure"
                                >
                                    <img
                                        :src="trashIcon"
                                        class="icon20 iconToWhite"
                                        alt="Löschen"
                                    >
                                </v-btn>
                                <v-select
                                    v-if="(accountRole === 'teacher' || accountRole === 'maintainer') &&
                                        !showCreateExternalApp && !isAppsEmpty"
                                    v-model="selectedGroups"
                                    dense
                                    hide-details
                                    rounded
                                    item-text="name"
                                    :menu-props="{offsetY: true,}"
                                    :items="myGroups"
                                    :item-value="item => item._id"
                                    no-data-text="Keine passende Gruppe"
                                    class="ma-0 pa-0 m-2"
                                    style="max-width: 300px;max-height: 60px"
                                    multiple
                                    dark
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <p v-if="index === 0">
                                            <span>{{ item.name }}</span>
                                        </p>
                                        <span
                                            v-if="index === 1"
                                            class="text-caption"
                                        >
                                            (+{{ selectedGroups.length - 1 }} andere)
                                        </span>
                                    </template>
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @mousedown.prevent
                                            @click="selectAll"
                                        >
                                            <v-list-item-action>
                                                <v-icon>
                                                    {{ selectIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Alle
                                                </v-list-item-title>
                                                <v-divider class="mt-2" />
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>
                                <v-btn
                                    v-if="showCreateExternalApp"
                                    min-width="20"
                                    class="pa-2 ml-2 justify-end text-none"
                                    elevation="0"
                                    color="success"
                                    @click="handleSave"
                                >
                                    Fertig
                                    <img
                                        :src="saveIcon"
                                        class="ml-2 icon20 iconToWhite"
                                        alt="speichern"
                                    >
                                </v-btn>
                                <v-tooltip
                                    v-if="showCreateExternalApp && currentApp"
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            x-small
                                            text
                                            v-bind="attrs"
                                            min-width="20"
                                            class="pa-0 ml-2 mr-3 justify-end"
                                            style="height: 20px; width: 20px;"
                                            v-on="on"
                                            @click="selectedApps.push(currentApp); toggleAreYouSure();"
                                        >
                                            <img
                                                :src="trashIcon"
                                                class="icon20 iconToWhite"
                                                alt="Löschen"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Löschen</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-center justify-end">
                        <!-- Create button and create menu -->
                        <div v-if="accountRole === 'teacher'|| accountRole === 'maintainer'">
                            <v-tooltip
                                top
                            >
                                <span>Elternbrief erstellen</span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
                <!-- Body -->
                <div
                    :key="showCreateExternalApp"
                    class="scroll-area"
                    data-simplebar 
                    :settings="settings"
                >
                    <div
                        v-if="showCreateExternalApp"
                        class="widgetBody justify-center"
                        style="justify-content: center; justify-items: center"
                    >
                        <div class="ma-5 align-center">
                            <h1
                                v-if="!currentApp"
                                class="headline"
                                style="align-self: center; text-align: center"
                            >
                                Neue Webseite verlinken
                            </h1>
                            <h1
                                v-else
                                class="headline"
                                style="align-self: center; text-align: center"
                            >
                                Webseite bearbeiten
                            </h1>
                            <v-text-field
                                id="appName"
                                v-model="appName"
                                absolute
                                label="Name eingeben"
                                @keydown.enter="handleEnterAppName"
                            />
                            <v-text-field
                                id="appUrl"
                                v-model="appUrl"
                                absolute
                                label="URL eingeben"
                                hint="https://www.website.de"
                                prefix="http://"
                                append-icon="mdi-web"
                                @keydown.enter="handleEnterAppUrl"
                                @click:append="clickOpenUrlEdit"
                            />
                            <icon-picker
                                class="align-center"
                                elevation="0"
                                :current-color="currentApp ? currentApp.iconColor : appColor"
                                :callback="(result) => {currentApp ? currentApp.iconColor = result: appColor = result}"
                                :current-icon="currentApp ? currentApp.icon : appIcon"
                                :callback-icon="(result) => {currentApp ? currentApp.icon = result : appIcon = result}"
                                :group-icons="icons"
                                :filter-icon-color-to-grey="true"
                                :color-picker-usage="'externalApp'"
                                style="align-self: center;"
                                @changeIcon="(icon) => { currentApp && currentApp.icon ? currentApp.icon = icon : appIcon = icon }"
                            />
                            <v-container fluid>
                                <p class="mr-5 mt-5 mb-1">
                                    Veröffentlichen für:
                                </p>
                                <v-select
                                    id="groupVselect"
                                    v-model="selectedGroups"
                                    outlined
                                    dense
                                    hide-details
                                    prom
                                    label="Klasse(n) auswählen"
                                    item-text="name"
                                    :menu-props="{offsetY: true,}"
                                    :item-value="item => item._id"
                                    :items="myGroups"
                                    no-data-text="Keine passende Gruppe"
                                    class="align-center"
                                    style="max-width: 278px; align-self: center;"
                                    multiple
                                    @keydown.enter="handleEnterSelectedGroups"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @mousedown.prevent
                                            @click="selectAll"
                                        >
                                            <v-list-item-action>
                                                <v-icon>
                                                    {{ selectIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Alle
                                                </v-list-item-title>
                                                <v-divider class="mt-2" />
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </v-container>
                        </div>
                    </div>
                    <div
                        v-else
                    >
                        <div
                            v-if="!showCreateExternalApp"
                            :class="listView ? 'appWidgetBodyList': 'appWidgetBody'"
                        >
                            <div
                                v-if="showMainApps && (accountRole === 'teacher' || accountRole === 'maintainer') && !diaryDeactivated"
                                :class="listView ? 'appContainerList' : smallView ? 'appContainer50' : 'appContainer'"
                            >
                                <v-btn
                                    fab
                                    small
                                    icon
                                    :class="listView ? 'listButton' : smallView ? 'button50' : 'button100'"
                                    @click="$emit('openDiary');"
                                >
                                    <img
                                        :src="bookIcon"
                                        class="icon20  mb-1"
                                        :class="listView ? 'listButtonImage' : smallView ? 'appSheet50 buttonImage50' : 'appSheet buttonImage align-center'"
                                        :style="listView ? 'background-color: var(--v-headerblue-base); padding: 3px':''"
                                        elevation="0"
                                        alt="Klassenbuch"
                                    >
                                </v-btn>
                                <p
                                    :class="listView ? ' appTextList mt-2' : smallView ? 'appTextSmall text-center' : 'appText text-center'"
                                >
                                    Klassenbuch
                                </p>
                            </div>
                            <hr
                                v-if="listView && (windowWidth <= 900 && showMainApps && (accountRole === 'teacher' || accountRole === 'maintainer') && !diaryDeactivated)"
                                class="listDivider"
                            >
                            <div
                                v-if="showMainApps && accountRole !== 'parent'"
                                ref="internetApp"
                                :class="listView ? 'appContainerList' : smallView ? 'appContainer50' : 'appContainer'"
                                @click="()=>{if(listView){showMainApps = false; showAppPicker = false; selectedApps = [];} }"
                            >
                                <ClickableBadge
                                    v-if="showInternetBadge"
                                    :event-amount="internetAppsNotSeen"
                                    @badgeClicked="showMainApps = false; showAppPicker = false; selectedApps = []"
                                />
                                <v-btn
                                    fab
                                    small
                                    icon
                                    :class="listView ? 'listButton' : smallView ? 'button50' : 'button100'"
                                    @click="showMainApps = false; showAppPicker = false; selectedApps = [];"
                                >
                                    <img
                                        :src="internetIcon"
                                        :class="listView ? 'listButtonImage' : smallView ? 'appSheet50 buttonImage50' : 'appSheet buttonImage align-center'"
                                        :style="listView ? 'background-color: var(--v-headerblue-base); ':''"
                                        elevation="0"
                                        alt="Internet"
                                    >
                                </v-btn>
                                <p
                                    :class="listView ? ' appTextList mt-2' : smallView ? 'appTextSmall text-center' : 'appText text-center'"

                                    style="cursor: pointer"
                                    @click="showSpeakerBtn('Internet', false)"
                                >
                                    Internet
                                </p>
                                <div id="Internet" />
                            </div>
                            <hr
                                v-if="listView && showMainApps && accountRole !== 'parent'"
                                class="listDivider"
                            >

                            <div
                                v-if="showMainApps && accountRole !== 'pupil' && accountRole !== 'parent'"
                                :class="listView ? 'appContainerList' : smallView ? 'appContainer50' : 'appContainer'"
                                @click="openExchange"
                            >
                                <v-btn
                                    fab
                                    small
                                    icon
                                    :class="listView ? 'listButton' : smallView ? 'button50' : 'button100'"
                                    @click="openExchange"
                                >
                                    <img
                                        :src="exchangeIcon"
                                        :class="listView ? 'listButtonImage' : smallView ? 'appSheet50 buttonImage50' : 'appSheet buttonImage align-center'"
                                        :style="listView ? 'background-color: var(--v-headerblue-base); padding: 3px':''"
                                        elevation="0"
                                        alt="Verleih"
                                    >
                                </v-btn>
                                <p
                                    :class="listView ? ' appTextList mt-2' : smallView ? 'appTextSmall text-center' : 'appText text-center'"
                                >
                                    Verleih
                                </p>
                            </div>
                            <hr
                                v-if="listView && (showMainApps && accountRole !== 'pupil' && accountRole !== 'parent')"
                                class="listDivider"
                            >
                            <div
                                v-if="showMainApps && !calendarDeactivated"
                                ref="calendarApp"
                                :class="listView ? 'appContainerList' : smallView ? 'appContainer50' : 'appContainer'"
                                @click="openCalendar"
                            >
                                <ClickableBadge
                                    v-if="showCalendarBadge"
                                    :event-amount="eventsNotSeen"
                                    @badgeClicked="openCalendar"
                                />
                                <v-btn
                                    fab
                                    small
                                    icon
                                    :class="listView ? 'listButton' : smallView ? 'button50' : 'button100'"
                                    @click="openCalendar"
                                >
                                    <img
                                        :src="calendarIcon"
                                        :class="listView ? 'listButtonImage' : smallView ? 'appSheet50 buttonImage50' : 'appSheet buttonImage align-center'"
                                        :style="listView ? 'background-color: var(--v-headerblue-base); padding:5px;':''"
                                        elevation="0"
                                        alt="Kalender"
                                    >
                                </v-btn>
                                <p
                                    :class="listView ? ' appTextList mt-2' : smallView ? 'appTextSmall text-center' : 'appText text-center'"

                                    @click="showSpeakerBtn('Kalender', false)"
                                >
                                    Kalender
                                </p>
                                <div id="Kalender" />
                            </div>
                            <hr
                                v-if="listView && (showMainApps && !calendarDeactivated)"
                                class="listDivider"
                            >

                            <div
                                v-if="showMainApps"
                                ref="pinboardApp"
                                :class="listView ? 'appContainerList' : smallView ? 'appContainer50' : 'appContainer'"
                                @click.stop="openPinboard"
                            >
                                <ClickableBadge
                                    v-if="showPinboardBadge"
                                    :event-list="pinboardNotSeen"
                                    @badgeClicked="showPinboardWidget = true"
                                />
                                <v-btn
                                    fab
                                    small
                                    icon
                                    :class="listView ? 'listButton' : smallView ? 'button50' : 'button100'"
                                    @click="openPinboard"
                                >
                                    <img
                                        :src="pinboardIcon"
                                        :class="listView ? 'listButtonImage' : smallView ? 'appSheet50 buttonImage50' : 'appSheet buttonImage align-center'"
                                        :style="listView ? 'background-color: var(--v-headerblue-base); ':''"
                                        elevation="0"
                                    >
                                </v-btn>
                                <p
                                    :class="listView ? ' appTextList mt-2' : smallView ? 'appTextSmall text-center' : 'appText text-center'"

                                    @click="showSpeakerBtn('Pinnwand', false)"
                                >
                                    Pinnwand
                                </p>
                                <div id="Pinnwand" />
                            </div>
                            <hr
                                v-if="listView && showMainApps"
                                class="listDivider"
                            >
                            <!-- Online Bib // Show all central document repository Files -->
                            <div
                                v-if="showMainApps && accountRole === 'teacher'"
                                :class="listView ? 'appContainerList' : smallView ? 'appContainer50' : 'appContainer'"
                                @click.stop="openLibrary"
                            >
                                <v-btn
                                    fab
                                    small
                                    icon
                                    :class="listView ? 'listButton' : smallView ? 'button50' : 'button100'"
                                    @click="openLibrary"
                                >
                                    <img
                                        :src="libaryIcon"
                                        :class="listView ? 'listButtonImage' : smallView ? 'appSheet50 buttonImage50' : 'appSheet buttonImage align-center'"
                                        :style="listView ? 'background-color: var(--v-headerblue-base); padding: 2px':''"
                                        elevation="0"
                                    >
                                </v-btn>
                                <p
                                    :class="listView ? ' appTextList mt-2' : smallView ? 'appTextSmall text-center' : 'appText text-center'"
                                >
                                    Online Bibliothek
                                </p>
                            </div>
                            <hr
                                v-if="listView && (showMainApps && accountRole === 'teacher')"
                                class="listDivider"
                            >
                            <div
                                v-if="showMainApps && accountRole !== 'maintainer' && accountRole !== 'parent'"
                                :class="listView ? 'appContainerList' : smallView ? 'appContainer50' : 'appContainer'"
                                @click.stop="openNewEditor"
                            >
                                <v-btn
                                    fab
                                    small
                                    icon
                                    :class="listView ? 'listButton' : smallView ? 'button50' : 'button100'"
                                    @click="openNewEditor"
                                >
                                    <img
                                        :src="editorIcon"
                                        :class="listView ? 'listButtonImage' : smallView ? 'appSheet50 buttonImage50' : 'appSheet buttonImage align-center'"
                                        :style="listView ? 'background-color: var(--v-headerblue-base); padding:2px;':''"
                                        elevation="0"
                                    >
                                </v-btn>
                                <p
                                    :class="listView ? ' appTextList mt-2' : smallView ? 'appTextSmall text-center' : 'appText text-center'"

                                    @click="showSpeakerBtn('Editor', false)"
                                >
                                    Editor
                                </p>
                                <div id="Editor" />
                            </div>
                            <hr
                                v-if="listView && (showMainApps && accountRole !== 'maintainer' && accountRole !== 'parent')"
                                class="listDivider"
                            >
                            <div
                                v-if="showMainApps && accountRole !== 'maintainer' && accountRole !== 'parent' && !babyView"
                                :class="listView ? 'appContainerList' : smallView ? 'appContainer50' : 'appContainer'"
                                @click.stop="openLibre('word')"
                            >
                                <v-btn
                                    fab
                                    small
                                    icon
                                    :class="listView ? 'listButton' : smallView ? 'button50' : 'button100'"
                                    @click="openLibre('word')"
                                >
                                    <img
                                        :src="wordIcon"
                                        :class="listView ? 'listButtonImage' : smallView ? 'appSheet50 buttonImage50' : 'appSheet buttonImage align-center'"
                                        :style="listView ? 'background-color: var(--v-headerblue-base); padding:2px;':''"
                                        elevation="0"
                                    >
                                </v-btn>
                                <p
                                    :class="listView ? ' appTextList mt-2' : smallView ? 'appTextSmall text-center' : 'appText text-center'"

                                    @click="showSpeakerBtn('Texte', false)"
                                >
                                    Texte
                                </p>
                                <div id="Texte" />
                            </div>
                            <hr
                                v-if="listView && (showMainApps && accountRole !== 'maintainer' && accountRole !== 'parent' && !babyView)"
                                class="listDivider"
                            >
                            <div
                                v-if="showMainApps && accountRole !== 'maintainer' && accountRole !== 'parent' && !babyView"
                                :class="listView ? 'appContainerList' : smallView ? 'appContainer50' : 'appContainer'"
                                @click.stop="openLibre('powerpoint')"
                            >
                                <v-btn
                                    fab
                                    small
                                    icon
                                    :class="listView ? 'listButton' : smallView ? 'button50' : 'button100'"
                                    @click="openLibre('powerpoint')"
                                >
                                    <img
                                        :src="powerpointIcon"
                                        :class="listView ? 'listButtonImage' : smallView ? 'appSheet50 buttonImage50' : 'appSheet buttonImage align-center'"
                                        :style="listView ? 'background-color: var(--v-headerblue-base); padding:2px;':''"
                                        elevation="0"
                                    >
                                </v-btn>
                                <p
                                    :class="listView ? ' appTextList mt-2' : smallView ? 'appTextSmall text-center' : 'appText text-center'"

                                    @click="showSpeakerBtn('Präsentation', false)"
                                >
                                    Präsentation
                                </p>
                                <div id="Präsentation" />
                            </div>
                            <hr
                                v-if="listView && (showMainApps && accountRole !== 'maintainer' && accountRole !== 'parent' && !babyView)"
                                class="listDivider"
                            >

                            <div
                                v-if="showMainApps && accountRole !== 'maintainer' && accountRole !== 'parent' && !babyView"
                                :class="listView ? 'appContainerList' : smallView ? 'appContainer50' : 'appContainer'"
                                @click.stop="openLibre('excel')"
                            >
                                <v-btn
                                    fab
                                    small
                                    icon
                                    :class="listView ? 'listButton' : smallView ? 'button50' : 'button100'"
                                    @click="openLibre('excel')"
                                >
                                    <img
                                        :src="excelIcon"
                                        :class="listView ? 'listButtonImage' : smallView ? 'appSheet50 buttonImage50' : 'appSheet buttonImage align-center'"
                                        :style="listView ? 'background-color: var(--v-headerblue-base); padding:2px;':''"
                                        elevation="0"
                                    >
                                </v-btn>
                                <p
                                    :class="listView ? ' appTextList mt-2' : smallView ? 'appTextSmall text-center' : 'appText text-center'"

                                    @click="showSpeakerBtn('Tabelle', false)"
                                >
                                    Tabelle
                                </p>
                                <div id="Tabelle" />
                            </div>
                            <hr
                                v-if="listView && (showMainApps && accountRole !== 'maintainer' && accountRole !== 'parent' && !babyView)"
                                class="listDivider"
                            >
                            <div
                                v-for="(app, appIndex) in myApps"
                                :key="appIndex"
                            >
                                <div
                                    :class="listView ? 'appContainerList ml-1' : smallView ? 'internetContainer50' : 'internetContainer'"
                                    @click.stop="clickExternalApp(app)"
                                >
                                    <ClickableBadge
                                        v-if="!app.seenByMe"
                                        :event-amount="1"
                                        @click="clickExternalApp(app)"
                                    />
                                    <v-checkbox
                                        v-if="!listView && showAppPicker"
                                        v-model="selectedApps"
                                        :value="app"
                                        dark
                                        style="position: absolute; z-index: 18;"
                                    />
                                    <v-btn
                                        v-if="!listView && (accountRole === 'teacher' || accountRole === 'maintainer')"
                                        icon
                                        style="position: relative; z-index:18; left: 0px; top: 0px;"
                                        class="iconToWhite"
                                        :style="smallView ? 'margin-left: 60%;':'margin-left:70%'"
                                        @click="loadAppInformation(app); showCreateExternalApp = true;"
                                    >
                                        <img
                                            :src="menuIcon"
                                            class="icon20 "
                                        >
                                    </v-btn>
                                    <div
                                        :ref="`internet${app._id}`"
                                        :class="listView ? 'listButton' : smallView ? 'internetSheet50 appImage50' : 'internetSheet align-center'"
                                        style="border-radius:16px;"
                                        :style="!app.hasUploadedIcon ?`background-color: ${app.iconColor};` :''" 
                                        elevation="0"
                                        @click="clickExternalApp(app)"
                                    >
                                        <img
                                            v-if="!app.hasUploadedIcon"
                                            :src="getIconText(app.icon)"
                                            :class="listView ? 'listButtonImage' : smallView ? 'appImg50':'appImg'"
                                            :style="`background-color: ${app.iconColor};padding: 3px;`"
                                        >
                                        <img
                                            v-else
                                            :src="app.iconBlob"
                                            :class="listView ? '':'appImg'"
                                            :style="listView ? 'object-fit: cover; border-radius: 10px; height: 35px;':'object-fit: cover; width: 100%; height: 100%; border-radius: 16px;'"
                                        >
                                    </div>

                                    <p
                                        :class="listView ? ' appTextList mt-2' : smallView ? 'appTextSmall text-center' : 'appText text-center'"
                                        @click="showSpeakerBtn(app.name, app._id)"
                                    >
                                        {{ app.name }}
                                    </p>
                                    <v-btn
                                        v-if="listView && (accountRole === 'teacher' || accountRole === 'maintainer')"
                                        icon
                                        :style="listView ? 'position: absolute; right: 10px;':'position: relative; z-index:18; right: -5px;'"
                                        @click="loadAppInformation(app); showCreateExternalApp = true;"
                                    >
                                        <img
                                            :src="menuIcon"
                                            class="icon20 "
                                            :class="listView ? '': ''"
                                        >
                                    </v-btn>
                                    <div :id="app._id" />
                                </div>
                                <hr
                                    v-if="listView "
                                    class="listDivider"
                                >
                            </div>
                            <div
                                v-if="accountRole !== 'pupil' && accountRole !== 'parent'"
                                :class="listView ? 'appContainerList' : smallView ? 'appContainer50' : 'appContainer'"
                                @click="showCreateExternalApp = true"
                            >
                                <v-btn
                                    fab
                                    small
                                    icon
                                    :class="listView ? 'listButton' : smallView ? 'button50' : 'button100'"
                                    @click="showCreateExternalApp = true"
                                >
                                    <img
                                        :src="plusIcon"
                                        :class="listView ? 'listButtonImage' : smallView ? 'appSheet50 buttonImage50' : 'appSheet buttonImage align-center'"
                                        :style="listView ? 'background-color: var(--v-headerblue-base); padding: 5px;' : ''"
                                        elevation="0"
                                        alt="Neue Externe Anwendung anlegen"
                                    >
                                </v-btn>

                                <p 
                                    :class="listView ? ' appTextList mt-2' : smallView ? 'appTextSmall text-center' : 'appText text-center'"
                                >
                                    Neue Verlinkung erstellen
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <exchange-app
            v-if="showExchangeWidget"
            :scanner-dialog="scannerDialog"
            @closeList="() => { showExchangeWidget = false; }"
            @closeScan="() => { scannerDialog = false } "
        />
        <pinboard-app
            v-if="showPinboardWidget"
            ref="pinboard"
            :pinboards="allPinboards"
            @closeList="closePinboardList"
            @pinboardMounted="showLoadingOverlay = false"
        />
        <calendar-app
            v-if="showCalendarWidget"
            @close="() => {showCalendarWidget = false; this.hasUserNewEvents();}"
        />
        <library-app
            v-if="showLibraryWidget"
            @closeList="() => {showLibraryWidget = false}"
        />
        <v-overlay
            absolute
            :value="showLoadingOverlay"
            style="width: 100% !important; z-index: 500 !important;"
        >
            <v-progress-circular
                v-if="showLoadingOverlay"
                indeterminate
                size="100"
            />
        </v-overlay>
        <div
            :ref="appId !== '' ? `speaker${appId}` : `speaker${applicationName}`"
            class="showSpeaker"
        >
            <v-btn
                v-if="accountRole === 'pupil' || accountRole === 'parent'"
                elevation="6"
                absolute
                x-small
                style="height: 30px; width:30px; background-color:white; border-radius: 4px !important; bottom: -25px; right: 5%;"
                @click="readText(applicationName, $event)"
            >
                <img
                    alt="Vorlesen"
                    :src="lautsprecherIcon"
                    style="height: 20px;"
                >
            </v-btn>
        </div>
        <!-- <BadgeExplanations
            v-if="windowWidth < 900"
            :small="true"
        /> -->
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {textToSpeech} from "@/util/textToSpeech";
import ExchangeApp from '../Exchange/ExchangeApp.vue';
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";
import internetPlusIcon from '@/assets/Icons/FaceliftIcons/internet_hinzufuegen_kontur.svg';
import backIcon from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg';
import trashIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import doneIcon from '@/assets/Icons/done.svg';
import calendarIcon from '@/assets/Icons/FaceliftIcons/Kalender_appicon.svg';
import internetIcon from '@/assets/Icons/internet.svg';
import pinboardIcon from '@/assets/Icons/pinnwand.svg';
import exchangeIcon from '@/assets/Icons/FaceliftIcons/Verleih_appicon.svg';
import saveIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';
import powerpointIcon from '@/assets/Icons/FaceliftIcons/libreoffice_ppt.svg';
import wordIcon from '@/assets/Icons/FaceliftIcons/libreoffice_doc.svg';
import excelIcon from '@/assets/Icons/FaceliftIcons/libreoffice_xls.svg';
import editorIcon from '@/assets/Icons/FaceliftIcons/edit_logo_apps.svg';
import libaryIcon from '@/assets/Icons/FaceliftIcons/bibliothek_app.svg';
import bookIcon from "../../assets/Icons/FaceliftIcons/Klassebuch_appicon.svg";
import {Browser} from '@capacitor/browser';
import IconPicker from "@/components/Utils/IconPicker";
import * as backend from '@/api/backend';
import PinboardApp from '../Pinboard/PinboardApp.vue';
import CalendarApp from "../Calendar/CalendarApp.vue";
import LibraryApp from "../FileArchive/LibraryApp.vue";
import erdkundeIconWeiss from '../../assets/Icons/FaceliftIcons/f-erdkunde-weiss.svg';
import religionIconWeiss from '../../assets/Icons/FaceliftIcons/f-religion-weiss.svg';
import politikIconWeiss from '../../assets/Icons/FaceliftIcons/f-politik-weiss.svg';
import bioIconWeiss from '../../assets/Icons/FaceliftIcons/f-biologie-weiss.svg';
import englischIconWeiss from "../../assets/Icons/FaceliftIcons/f-englisch-weiss.svg";
import pinIconWeiss from "../../assets/Icons/FaceliftIcons/pin_alt_weiss.svg";
import chemieIconWeiss from '../../assets/Icons/FaceliftIcons/f-chemie-weiss.svg';
import deutschIconWeiss from '../../assets/Icons/FaceliftIcons/f-deutsch-weiss.svg';
import geschichteIconWeiss from '../../assets/Icons/FaceliftIcons/f-geschichte-weiss.svg';
import matheIconWeiss from '../../assets/Icons/FaceliftIcons/f-mathe-weiss.svg';
import plusIcon from "@/assets/Icons/plus.svg";
import musikIconWeiss from '../../assets/Icons/FaceliftIcons/f-musik-weiss.svg';
import physikIconWeiss from '../../assets/Icons/FaceliftIcons/f-physik-weiss.svg';
import sportIconWeiss from '../../assets/Icons/FaceliftIcons/f-sport-weiss.svg';
import wirtschaftIconWeiss from '../../assets/Icons/FaceliftIcons/f-wirtschaft-weiss.svg';
import kunstIconWeiss from '@/assets/Icons/FaceliftIcons/f-kunst-weiss.svg';
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";
import ClickableBadge from "@/components/Utils/ClickableBadge";
import EventBus from "../../util/eventBus";
import menuIcon from '@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg';
import BadgeExplanations from '../Utils/BadgeExplanations.vue';

export default {
    name: "AppWidget",
    components: {
        ClickableBadge,
        IconPicker,
        AreYouSurePopup,
        ExchangeApp,
        PinboardApp,
        CalendarApp,
        LibraryApp,
        // BadgeExplanations,
    },
    props: {
        pinboards: { required: false, type: Array },
        reset: {required: false, type: Boolean}
    },
    data: () => ({
         rerenderComponent: true,
        expandExchange: false,
        expandPinboard: false,
        expandLibrary: false,
        showExchangeWidget: false,
        showPinboardWidget: false,
        expandCalendar: false,
        showCalendarWidget: false,
        showLibraryWidget: false,
        scannerDialog: false,
        babyView: false,
        showLoadingOverlay: false,

        allPinboards: [],
        pinboardNotSeen: [],
        showInternetBadge: false,
        internetAppsNotSeen: '',

        libreAccessToken: '',
        fileType: '',
        libreDocId: '',
        libreFile: null,

        showCalendarBadge: false,
        eventsNotSeen: '',

        //Show elements for changing/creating an external app?
        showCreateExternalApp: false,
        //Show checkbox next to apps to delete them?
        showAppPicker: false,
        //Show Apps inside App-Widget or those inside "Internet"-App
        showMainApps: true,
        //All apps relevant to the user
        externalApps: [],
        //All apps selected to delete
        selectedApps: [],
        //All groups selected (Is used for filtering existing apps and creating new apps)
        selectedGroups: [],
        //All groups
        groups: [],

        showAreYouSure: false,

        showOpenWebsitePopup: false,
        editorFolderId: null,

        urlToOpen: '',

        //Current clicked app, useful to check if new app is being created or old one is being changed
        currentApp: null,
        //Model for the name of app
        appName: '',
        //Model for the url of app
        appUrl: '',

        appIcon: 'pinIconWeiss',
        appColor: '#fcd20a',

        //Object of the current account if accountRole is teacher
        teacher: null,

        icons:[
            {icon: pinIconWeiss, text: "pinIconWeiss"},
            {icon: englischIconWeiss, text: "englischIconWeiss"},
            {icon: politikIconWeiss, text: "politikIcon"},
            {icon: erdkundeIconWeiss, text: "erdkundeIcon"},
            {icon: religionIconWeiss, text: "religionIcon"},
            {icon: bioIconWeiss, text: "bioIcon"},
            {icon: chemieIconWeiss, text: "chemieIcon"},
            {icon: deutschIconWeiss, text: "deutschIcon"},
            {icon: geschichteIconWeiss, text: "geschichteIcon"},
            {icon: matheIconWeiss, text: "matheIcon"},
            {icon: musikIconWeiss, text: "musikIcon"},
            {icon: kunstIconWeiss, text: "kunstIcon"},
            {icon: physikIconWeiss, text: "physikIcon"},
            {icon: sportIconWeiss, text: "sportIcon"},
            {icon: wirtschaftIconWeiss, text: "wirtschaftIcon"},
        ],

        applicationName: '',
        appId: '',
        listView: false,
        menuIcon,
        trashIcon,
        backIcon,
        doneIcon,
        internetIcon,
        pinboardIcon,
        exchangeIcon,
        calendarIcon,
        internetPlusIcon,
        saveIcon,
        powerpointIcon,
        wordIcon,
        plusIcon,
        excelIcon,
        editorIcon,
        bookIcon,
        libaryIcon,
        lautsprecherIcon,
        lautsprecherAusIcon,

        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
        },
    }),
    computed: {
        ...mapState('util', ['windowWidth']),
        ...mapState('translation', ['targetLang',]),
        ...mapGetters('auth', [ 'accountId', 'accountRole' ]),
        ...mapGetters('vfs', [ 'vfsGridWidthPctLibrary' ]),
        ...mapGetters('pinboard', [ 'openPinboardLibrary' ]),
        ...mapGetters('deactivatedFeatures',['deactivatedFeatures', 'appsDeactivated','chatDeactivated','brettDeactivated','planDeactivated',
                                                'messagesDeactivated','filesDeactivated','calendarDeactivated','diaryDeactivated','pinboardDeactivated',]),

        smallView(){
            return this.vfsGridWidthPctLibrary === 50  ? true : false;
        },

        // listView(){
        //     return this.vfsGridWidthPctLibrary === 33  ? true : false;
        // },
        numberNotSeen() {
            return this.pinboardNotSeen.length;
        },
        showPinboardBadge() {
            return this.pinboardNotSeen.length > 0;
        },
        openWebsiteDialog() {
            return 'Eklara ist im Begriff die Website ' + this.urlToOpen + ' in einem neuen Tab zu öffnen!'
        },
        isAppsEmpty() {
            return this.myApps.length === 0 && this.selectedGroups.length !== 0 ;
        },
        //Are all groups selected?
        selectedAllGroups() {
            return this.selectedGroups.length === this.myGroups.length;
        },
        //Aresome groups selected?
        selectedSomeGroups() {
            return this.selectedGroups.length > 0 && !this.selectedAllGroups;
        },
        //Which icon to render as @v-select list item "Alle"
        selectIcon () {
            if (this.selectedAllGroups) return 'mdi-close-box';
            if (this.selectedSomeGroups) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
        //All groups relevant to the user
        myGroups() {
            if (this.accountRole === 'maintainer') {
                //all groups
                return this.groups;
            } else {
                //all groups where leader is current account
                return this.groups.filter(group => group.leaders.includes(this.teacher._id));
            }
        },
        //All apps relevant to the user
        myApps() {
            if (this.accountRole === 'pupil' || this.accountRole === 'parent') {
                //return the apps inside the "internet" folder or return those directly inside the AppWidget?
                return this.externalApps.filter(app => app.isMain === this.showMainApps);
            }
            if (!Array.isArray(this.selectedGroups)) {
                return this.externalApps.filter(app => (app.group._id === this.selectedGroups._id &&  app.isMain === this.showMainApps));
            } else {
                //Return all groups apps that are assigned to the currently selected groups
                if(this.externalApps){
                    return this.externalApps.filter(app => {
                        return (app.group && this.selectedGroups.includes(app.group._id) &&  app.isMain === this.showMainApps);
                    });
                }
               return [];
            }
        },
        eventBadgeList() {
            let eventList = [];

            // For the internet apps that are not shown on the main screen
            for(let i = 0; i < this.internetAppsNotSeen; i++) {
                eventList.push({
                    category: 'internet',
                    item: 'i' + i
                });
            }

            for(let i = 0; i < this.eventsNotSeen; i++) {
                eventList.push({
                    category: 'calendar',
                    item: 'c' + i
                });
            }

            for(let i = 0; i < this.numberNotSeen; i++) {
                eventList.push({
                    category: 'pinboard',
                    item: 'p' + i
                });
            }

            this.myApps.forEach((app) => {
                if (!app.seenByMe) {
                    eventList.push({
                        category: 'myApp',
                        item: app._id
                    });
                }
            })
            this.$emit('updateEventCount', eventList.length);
            return eventList;
        },
        smallEventList() {
            let smallEventList = [];

            // For the internet apps that are not shown on the main screen
            if(this.internetAppsNotSeen > 0) {
                smallEventList.push({
                    category: 'internet',
                    item: 'i' + 1
                });
            }

            if(this.eventsNotSeen > 0) {
                smallEventList.push({
                    category: 'calendar',
                    item: 'c' + 1
                });
            }

            if(this.numberNotSeen > 0) {
                smallEventList.push({
                    category: 'pinboard',
                    item: 'p' + 1
                });
            }

            this.myApps.forEach((app) => {
                if (!app.seenByMe) {
                    smallEventList.push({
                        category: 'myApp',
                        item: app._id
                    });
                }
            })
            return smallEventList;
        }
    },
    watch: {
        appUrl(newValue) {
            if(newValue.includes('http://')) {
                this.appUrl = this.appUrl.replace('http://', '');
            }
            if(newValue.includes('https://')) {
                this.appUrl = this.appUrl.replace('https://', '');
            }
        },
         reset(newVal, oldVal){
            if(newVal && newVal === true ){
                this.rerenderComponent = false;

                    this.$nextTick(() => {
                    // Add the component back in
                    this.rerenderComponent = true;
                    });
                if (this.showCreateExternalApp) {
                    if (!(this.appUrl === '' && this.appName === '')) {
                        if (this.currentApp) {
                            this.clickPatchExternalApp();
                        } else {
                            this.sendExternalApp();
                        }
                    }
                }

                this.showMainApps = !(this.showCreateExternalApp && !this.showMainApps);
                this.showCreateExternalApp = false;
                this.resetAppInformation();
                this.showAppPicker = false;
                this.$emit('appWidgetRemounted');
            }
        },
        vfsGridWidthPctLibrary(newVal){
            if(newVal === 33){
                this.listView = true;
            }else{
                this.listView = false;

            }
        }
    },
    beforeMount() {
        this.checkPinboardLibrary();
        if(this.vfsGridWidthPctLibrary === 33){
                this.listView = true;
        }else{
                this.listView = false;
        }
    },
    async mounted() {
        // Evaluate whether pupil uses baby view
        const t0 = performance.now();
        if (this.accountRole === 'pupil') {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
        }
        this.currentAccount = await this.getCurrentAccount();
        await this.refreshPinboards();
        const t2 = performance.now();
        this.getHasUserSeenPinboard();
        const t3 = performance.now();
        console.debug(`AppWidget getHasUserSeenPinboard: ${t3 - t2} ms.`);

        const t4 = performance.now();
        //check if user has new calendar events
        await this.hasUserNewEvents(true);
        const t5 = performance.now();
        console.debug(`AppWidget hasUserNewCalenderEvents performance: ${t5 - t4} ms.`);
        //request every app
        const t6 = performance.now();
        await this.getAllApps();
        const t7 = performance.now();
        console.debug(`AppWidget getAllApps performance: ${t7 - t6} ms.`);

        //get teacher info if user is teacher
        this.teacher = this.accountRole === 'teacher' ? await this.getMeTeacher() : '';
        //get all groups
        this.groups = this.accountRole === 'teacher' || this.accountRole === 'maintainer' ? await this.getGroupsInfo() : [];
        //Remove group "all" from array
        this.groups.splice(0, 1)
        //Select every group so there is no filter
        this.selectAll();
        this.hasUserNewInternetApps(true);
        this.calculateBadge();
        EventBus.$on('pinboard-new',async (body) => {
            let receivedPinboard = body.createdPinboard;
            receivedPinboard = await this.requestUploadedIcon(receivedPinboard);
            this.allPinboards.push(receivedPinboard);
        });
        EventBus.$on('pinboard-delete', (pinboardId) => {
            this.allPinboards = this.allPinboards.filter((pinboard) => pinboard._id !== pinboardId);
        });
        EventBus.$on('pinboard-patch',async (patchedPinboard) => {
            if (!this.allPinboards.find((pinboard) => pinboard._id === patchedPinboard._id)) {
                patchedPinboard = await this.requestUploadedIcon(patchedPinboard);
                this.allPinboards.push(patchedPinboard);
            }
            this.allPinboards = this.allPinboards.map((pinboard) => {
                return pinboard._id === patchedPinboard._id ? patchedPinboard : pinboard;
            });
        });
        EventBus.$on('pinboard-badge', () => {
            this.pinboardNotSeen.push({
                amount: 1,
                seen: false,
            })
        });
        EventBus.$off('new-external-app');
        EventBus.$on('new-external-app', async (body) => {
            if (body.sender === this.accountId) return;

            this.pushNewExternalApp(body.externalApp);
             this.externalApps = await this.getAllApps(true);
        //todo fix this will be inefficient on too many apps
        });
        EventBus.$off('delete-app');
        EventBus.$on('delete-app', async (appId) => {

             this.externalApps = await this.getAllApps(true);
        //todo fix this will be inefficient on too many apps
        });
         const t1 = performance.now();
        console.debug(`AppWidgetMounted in: ${t1 - t0} ms.`);
        this.$emit('appwidgetMounted');
    },
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions('externalApp', ['getAllExternalApps', 'postExternalApp', 'deleteExternalApp', 'patchExternalApp', 'getExternalApps', 'markExternalAppSeen','pushNewExternalApp']),
        ...mapActions("groups", ['getGroupsInfo']),
        ...mapActions("teachers", ['getMeTeacher']),
        ...mapActions('auth', ['getCurrentAccount']),
        ...mapActions("calendar", ['getUnseenEventsForUser']),
        ...mapActions('virtualFileSystem', [ 'getVFSFolderUploadsV2', 'getVFSAppFolderByNameV2' ]),
        ...mapActions('pinboard', ['getPinboards', 'hasUserSeenPinboard']),
        ...mapActions("pupils", ["getMePupil"]),
        ...mapActions("uploadedIcons", ["getUploadedIcon"]),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),
        ...mapMutations("badges", ["setAppBadge"]),
        ...mapMutations("vfs", ["mutateVfsGridWidthPctLibrary"]),

        async requestUploadedIcon(pinboard) {
            pinboard.iconBlob = pinboard.uploadedIcon ? await this.getUploadedIcon(pinboard.icon) : null;
            return pinboard;
        },
        getIconText(icon) {
            return this.icons.find((el) => el.text === icon)?.icon;
        },
        async openLibre(fileFormat) {
            const res = await backend.createLibreTemplate(fileFormat, this.currentAccount._id);
            const jsonRes = await res.json();
            const libreDoc = jsonRes.libreDoc;
            this.libreFile = jsonRes.file;
            this.libreAccessToken = jsonRes.accessToken;
            this.libreDocId = libreDoc._id;
            this.fileType = fileFormat;
            const query = {
                ...this.$route.query,
                fileId: this.libreFile._id,
                libreDocId: this.libreDocId,
                accessToken: this.libreAccessToken,
                isTemplate: true,
                parentComponent: 'apps',
                fileType: this.fileType,
                fileAccessToken: jsonRes.file.accessToken,
                mode: 'default',
            };

            await this.$router.push({
                name: 'libreoffice',
                query,
            });
        },
        handleEnterSelectedGroups() {
            if (this.selectedGroups.length === 0) {
                this.showSnackbar({
                    message: 'Achten Sie bitte darauf, dass mindestens eine Gruppe ausgewählt wurde!',
                    color: 'error',
                });
                return;
            }

            if (this.appName.trim() === '') {
                document.getElementById("appName").focus({focusVisible: true});
                return;
            }

            if (this.appUrl.replace('http://', '').trim() === '') {
                document.getElementById('appUrl').focus({focusVisible: true});
                return;
            }


            this.handleSave();
        },
        handleEnterAppName() {
            if (this.appName.length < 3) {
                this.showSnackbar({
                    message: 'Name der App muss mindestens 3 Zeichen lang sein',
                    color: 'error'
                });
                return;
            }
            if (this.appUrl.replace('http://', '').trim() === '') {
                document.getElementById('appUrl').focus({focusVisible: true});
                return;
            }

            if (this.selectedGroups.length === 0) {
                document.getElementById('groupVselect').focus({focusVisible: true});
                return;
            }

            this.handleSave();
        },
        async handleEnterAppUrl() {
            this.appUrl = 'http://' + this.appUrl;
            const isInvalid = this.isInvalidUrl(this.appUrl);
            if(isInvalid) {
                this.handleInvalidUrl(isInvalid);
                return;
            }

            if (this.appName.trim() === '') {
                document.getElementById("appName").focus({focusVisible: true});
                return;
            }
            if (this.selectedGroups.length === 0) {
                document.getElementById('groupVselect').focus({focusVisible: true});
                return;
            }

            this.fixAppUrl();

            this.handleSave();
        },
        clickOpenUrlEdit() {
            if (this.appUrl === '') {
                return;
            }
            this.showOpenWebsitePopup = true;
            this.urlToOpen = 'http://' + this.appUrl;
        },
        async getHasUserSeenPinboard() {
            this.pinboardNotSeen = [];
            for(var i = 0; i < this.allPinboards.length; i++) {
                const crtPinboard = this.allPinboards[i];
                const t0 = performance.now();
                const resJson = await this.hasUserSeenPinboard( { pinboardId: crtPinboard._id, accountId: this.currentAccount._id } );
                const t1 = performance.now();
                console.debug(`backend hasUserSeenPinboard : ${t1 - t0} ms.`);
                if(!resJson.seen) {
                    this.pinboardNotSeen.push(resJson.seen);
                }
            }

        },
        async refreshPinboards() {
            this.allPinboards = await this.getPinboards();
        },
        closePinboardList() {
            this.refreshPinboards();
            this.getHasUserSeenPinboard();
            this.showPinboardWidget = false;
            this.calculateBadge();
        },
        isInvalidUrl(url) {
            if(!url.includes('.')) {
                return 'dots';
            }
            if(url.length < 12) {
                return 'length';
            }
            return false;
        },
        toggleAreYouSure() {
            if(this.selectedApps.length === 0) {
                this.showAppPicker = false;
                return;
            }

            this.showAreYouSure = !this.showAreYouSure;
        },
        handleSave() {
            if (this.currentApp) {
                this.clickPatchExternalApp();
            } else {
                this.sendExternalApp();
            }
        },
        async handleBackClick() {
            if (this.showCreateExternalApp) {
                if (!(this.appUrl === '' && this.appName === '')) {
                    if (this.currentApp) {
                        await this.clickPatchExternalApp();
                    } else {
                        await this.sendExternalApp();
                    }
                }
            }

            this.showMainApps = !(this.showCreateExternalApp && !this.showMainApps);
            this.showCreateExternalApp = false;
            this.resetAppInformation();
            this.showAppPicker = false;
        },
        //Select all or de-select all
        async selectAll() {
            if(this.selectedAllGroups) {
                this.selectedGroups = [];
            } else {
                this.selectedGroups = [];
                for(const group of this.myGroups) {
                    this.selectedGroups.push(group._id);
                }
            }
        },
        async clickPatchExternalApp() {
            this.appUrl = 'http://' + this.appUrl;
            const isInvalid = this.isInvalidUrl(this.appUrl);
            if(isInvalid) {
                this.handleInvalidUrl(isInvalid);
                this.fixAppUrl();
                return;
            }
            if(this.selectedGroups.length <= 0) {
                this.showSnackbar({
                    message: 'Achte bitte darauf, dass mind. eine Gruppe ausgewählt wurde.',
                    color: 'error'
                });
                this.fixAppUrl();
                return;
            }
            if (this.appName.length < 3) {
                this.showSnackbar({
                    message: 'Name der App muss mindestens 3 Zeichen lang sein',
                    color: 'error'
                });
                this.fixAppUrl();
                return;
            }
            try {
                if(this.selectedGroups.length > 1) {
                    await this.deleteExternalApp(this.currentApp._id);
                    this.appUrl = 'http://' + this.appUrl;
                    await Promise.all(this.selectedGroups.map(async (group) => {
                        const res = await this.postExternalApp({
                            url: this.appUrl,
                            name: this.appName,
                            isMain: this.showMainApps,
                            group: group._id ? group._id : group,
                            iconColor: this.currentApp.iconColor,
                            icon: this.currentApp.icon,
                            hasUploadedIcon: !this.getIconText(this.currentApp.icon),
                        });
                    }));
                } else {
                    const res = await this.patchExternalApp({
                        extAppId: this.currentApp._id,
                        url: this.appUrl,
                        name: this.appName,
                        isMain: this.showMainApps,
                        group: this.selectedGroups._id,
                        iconColor: this.currentApp.iconColor,
                        icon: this.currentApp.icon,
                        hasUploadedIcon: !this.getIconText(this.currentApp.icon)
                    });
                }
                this.resetAppInformation();
                await this.getAllApps(true);

                this.showSnackbar({
                    message: 'Änderungen wurden gespeichert und übernommen!',
                    color: 'success'
                });
            } catch(error) {
                console.error(error);
                this.showSnackbar({
                    message: 'Es ist ein unerwarteter Fehler aufgetreten!',
                    color: 'error'
                });
                this.resetAppInformation();
            }
        },
        loadAppInformation(app) {
            this.currentApp = app;
            this.appName = app.name;
            this.appUrl = app.url;
            this.fixAppUrl();
            this.selectedGroups = [];
            this.selectedGroups.push(app.group);
        },
        //Handles the click on a specific app
        async clickExternalApp(app) {
            if (!app.seenByMe) {
                await this.markExternalAppSeen(app._id);
                app.seenByMe = true;
                this.calculateBadge();
            }
            //Is delete mode currently on?
            if (this.showAppPicker) {
                if (this.selectedApps.includes(app)) {
                    const index = this.selectedApps.indexOf(app);
                    this.selectedApps.splice(index, 1);
                } else {
                    this.selectedApps.push(app);
                }
            } else {
                this.urlToOpen = app.url;
                this.showOpenWebsitePopup = true;
            }
        },
        async deleteSelectedApps() {
            if(this.selectedApps.length <= 0) {
                this.showAppPicker = false;
                return;
            }

            try {
                if (Array.isArray(this.selectedApps)) {
                    await Promise.all(this.selectedApps.map(async (app) => {
                        const res = await this.deleteExternalApp(app._id);
                    }));
                } else {
                    const res = await this.deleteExternalApp(this.selectedApps._id);
                }

                this.selectedApps = [];
                this.showAppPicker = false;
                await this.resetAppInformation();
                await this.getAllApps(true);

                this.showSnackbar({
                    message: 'Löschung erfolgreich',
                    color: 'success'
                });
                this.showAreYouSure = false;
            } catch (err) {
                console.error(err);
                this.showSnackbar({
                    message: 'Es ist ein unerwarteter Fehler aufgetreten!',
                    color: 'error'
                });
            }
        },
        openUrl(url) {
            // dont make this async, safari doesnt like indirect openings
            // window api is more stable
            window.open(url, '_blank');
            this.showOpenWebsitePopup = false;
        },

        handleInvalidUrl(message) {
            if(message === 'dots') {
                this.showSnackbar({
                    message: 'Die URL scheint keine domain am Ende zu haben, das wäre z.B. .de',
                    color: 'error'
                });
            } else if  (message === 'length'){
                this.showSnackbar({
                    message: 'Die URL scheint zu kurz zu sein. Mindestlänge ist 12 Zeichen (https:// wird mitgezählt)',
                    color: 'error'
                });
            }
        },

        async sendExternalApp() {
            this.appUrl = 'http://' + this.appUrl;
            const isInvalid = this.isInvalidUrl(this.appUrl);
            if(isInvalid) {
                this.handleInvalidUrl(isInvalid);
                this.fixAppUrl();
                return;
            }
            if(this.selectedGroups.length <= 0) {
                this.showSnackbar({
                    message: 'Achte bitte darauf, dass mind. eine Gruppe ausgewählt wurde.',
                    color: 'error'
                });
                this.fixAppUrl();
                return;
            }
            if(this.appName.length < 3) {
                this.showSnackbar({
                    message: 'Name der App muss mindestens 3 Zeichen lang sein',
                    color: 'error'
                });
                this.fixAppUrl();
                return;
            }
            try {
                if (Array.isArray(this.selectedGroups)) {
                    await Promise.all(this.selectedGroups.map(async (group) => {
                        const res = await this.postExternalApp({
                            url: this.appUrl,
                            name: this.appName,
                            isMain: this.showMainApps,
                            group: group,
                            iconColor: this.appColor,
                            icon: this.appIcon,
                            hasUploadedIcon: !this.getIconText(this.appIcon)
                        });
                    }));
                } else {
                    const res = await this.postExternalApp({
                        url: this.appUrl,
                        name: this.appName,
                        isMain: this.showMainApps,
                        group: this.selectedGroups,
                        iconColor: this.appColor,
                        icon: this.appIcon,
                        hasUploadedIcon: !this.getIconText(this.appIcon)
                    });
                }

                await this.resetAppInformation();
                await this.getAllApps(true);

                this.showSnackbar({
                    message: 'Erstellung erfolgreich!',
                    color: 'success'
                });
            } catch (err) {
                console.warn(err);
                this.showSnackbar({
                    message: 'Es ist ein unerwarteter Fehler aufgetreten!',
                    color: 'error'
                });
                this.fixAppUrl();
            }
        },

        clickWidgetBody() {
            // seems like redundant booleans? did not find any meaningful usage
            this.expandExchange = false;
            this.expandCalendar = false;
            this.expandLibrary = false;
        },

        clickExchange(event) {
            event.stopPropagation();
            this.expandExchange = !this.expandExchange;
        },

        clickPinboard(event) {
            event.stopPropagation();
            this.expandPinboard = !this.expandPinboard;
        },

        clickLibrary(event) {
            event.stopPropagation();
            this.expandLibrary = !this.expandLibrary;
        },

        openExchange(event) {
            this.clickExchange(event);
            this.showExchangeWidget = true;
        },

        openPinboard(event) {
            this.clickPinboard(event);
            this.showPinboardWidget = true;
        },

        openLibrary(event) {
            this.clickLibrary(event);
            this.showLibraryWidget = true;
        },

        async openNewEditor(event) {
            event.stopPropagation();
            let mode;
            if (this.accountRole === 'maintainer' || this.accountRole === 'teacher') {
                mode = 'creator';
            } else {
                mode = 'pupil';
            }

            // get id of users editor app folder (dont hate pls)
            if (!this.editorFolderId) {
                const appFolder = await this.getVFSAppFolderByNameV2({
                    appFolderName: 'Editor'
                });
                this.editorFolderId = appFolder._id;
            }

            const query = {
                ...this.$route.query,
                color: '#6996cd',
                aId: '',
                sharing: false,
                title: 'Editor ' + new Date().toLocaleDateString(),
                pagetype: 'empty',
                targetFolder: this.editorFolderId
            };
            delete query.aId;
            delete query.draftId;
            // Mode is always creator because we open a new file
            await this.$router.push({
                name: 'editor',
                params: {mode: mode},
                query
            });
        },

        async getAllApps(update) {
            if (this.accountRole === 'maintainer') {
                //Get EVERY app
                this.externalApps = await this.getAllExternalApps(update);
            } else {
                //Get apps with same group as current account
                this.externalApps = await this.getExternalApps({
                    userId: this.accountId,
                    update
                });
            }
            await this.requestUploadedIcons();
        },
        async requestUploadedIcons() {
            this.externalApps = await Promise.all(this.externalApps.map(async (app) => ({
                ...app,
                iconBlob: app.hasUploadedIcon ? await this.getUploadedIcon(app.icon) : null,
            })));
        },
        //Resets the v-models
        resetAppInformation() {
            this.appName = '';
            this.appUrl = '';
            this.currentApp = null;
            this.selectedGroups = [];
            this.selectAll();
            this.showCreateExternalApp = false;
        },
        fixAppUrl() {
            this.appUrl = this.appUrl.replace('http://', '');

            return this.appUrl;
        },
        clickCalendar(event) {
            event.stopPropagation();
            this.expandCalendar = !this.expandCalendar;
        },

        openCalendar(event) {
            this.clickCalendar(event);
            this.showCalendarWidget = true;
        },

        async hasUserNewEvents(mounted) {
            this.showCalendarBadge = false;
            const eventsNotSeen = await this.getUnseenEventsForUser();
            this.eventsNotSeen = eventsNotSeen.length;

            if (this.eventsNotSeen > 0) {
                this.showCalendarBadge = true;
            }
             if(!mounted){
                this.calculateBadge();
            }
        },

        hasUserNewInternetApps(mounted) {
            this.showInternetBadge = false;
            const unseenInternetApps = this.externalApps.filter(app => app.isMain === false && app.seenByMe === false);
            this.internetAppsNotSeen = unseenInternetApps.length;

            if (this.internetAppsNotSeen > 0) {
                this.showInternetBadge = true;
            }
            if(!mounted){
                this.calculateBadge();
            }
        },

        calculateBadge() {
            console.count('APPWIDGET: calculatingBadges');
            const t0 = performance.now();
            let count = 0;
            count = this.numberNotSeen + this.eventsNotSeen + this.internetAppsNotSeen;
            this.myApps.forEach((app) => {
                if (!app.seenByMe) {
                    count++;
                }
            })
            if (count <= 0) {
                this.setAppBadge(false);
            } else {
                this.setAppBadge(count);
            }
            const t1 = performance.now();
            console.debug(`calcBadge AppWidget perfomance ${t1-t0}ms`)
        },
        // scroll chat into view and set is as seen
        appBadgeClicked(neededEvent) {
            let element;
            // find referenced chat element
            if(neededEvent.category === 'myApp') {
                element = this.$refs[`internet${neededEvent.item}`][0];
            } else {
                element = this.$refs[`${neededEvent.category}App`];
            }
            // scroll element into view
            element.scrollIntoView({
                behaviour: 'smooth',
                block: 'end',
            });
            this.$nextTick(() => {
                if (element) {
                    element.classList.remove('badgeEventVisualize');
                    element.classList.add('badgeEventVisualize');

                    setTimeout(() => {
                        element.classList.remove('badgeEventVisualize');
                    }, 1050);
                }
            });
        },

        checkPinboardLibrary() {
            // Open pinboard library if you just came back from a pinboard
            if (this.openPinboardLibrary) {
                this.showLoadingOverlay = true;
                this.showPinboardWidget = true;
            }
        },
        showSpeakerBtn(name, id){
            let refsid;
            let element;

            if (id) {
                this.appId = id;
                refsid = id;
            } else {
                refsid = name;
            }
            this.applicationName = name;

            const targetDiv = document.getElementById(`${refsid}`);

            this.$nextTick(() => {
                element = this.$refs[`speaker${refsid}`];

                if (element) {
                    targetDiv.appendChild(element);
                    element.classList.remove('showSpeaker');

                    setTimeout(() => {
                        element.classList.add('showSpeaker');
                    }, 3000);
                }
                this.appId = '';
            });
        },
        async readText(text, event) {
            let txt;
            let tmp = document.createElement("DIV");
            tmp.innerHTML = text;
            txt = tmp.textContent || tmp.innerText || "";

            if (this.targetLang !== 'de') {
                txt = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: txt
                });

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            await textToSpeech(txt, this.targetLang, () => {
                event.target.src = lautsprecherAusIcon;
                event.target.alt = 'Stop';
            }, () => {
                event.target.src = lautsprecherIcon;
                event.target.alt = 'Vorlesen';
            });
        },
        async changeAppearance(){
            console.log('CHANGING WIDTH');
            this.mutateVfsGridWidthPctLibrary();
        }
    }
}
</script>

Classes for the whole widget
<style scoped>
.showSpeaker{
    display: none;
}

.header {
    height: 56px;
    background-color: var(--v-headerblue-base);
    border-radius: 16px 16px 0 0;
    padding: 10px 0;
    color: white;
}

.appWidgetBody {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 5px;
    min-height: 100%;
    padding-bottom: 40px;
}
.appWidgetBodyList {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 5px;
    background-color: white;
    border-radius: 0 0 16px 16px;
}

.widgetBody {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    overflow-x: hidden;
    height: 100%;
}

.appSheet {
    display: flex;
    height: 100px;
    white-space: nowrap;
    justify-content: center;
    cursor: pointer;
    aspect-ratio: 1/1;
}
.appSheet50 {
    display: flex;
    height: 67px;
    white-space: nowrap;
    justify-content: center;
    cursor: pointer;
    aspect-ratio: 1/1;
}
.appImg{
    align-self: center;
    height: 8vh;
    border-radius: 16px;
}
.appImg50{
    align-self: center;
    border-radius: 16px;
    height: 5vh;
}
.appIcon {
    align-self: center;
    font-size: 500%;
}

.appText {
    color: var(--v-dunkelgrau-base);
    margin-top: 2px;
}
.appTextList {
    font-size: 18px;
}
.appTextSmall {
    margin-top: .25rem;
    font-size: 12px;
}
.appContainer {
    height: 100px;
    width: 100px;
    margin: 10px;
    margin-bottom: 45px;
    box-shadow: 0px 4px 6px -3px #888888;
    background-color: var(--v-headerblue-base); border-radius: 16px
}

.appContainer50 {
    height: 67px;
    width: 67px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 45px;
    box-shadow: 0px 4px 6px -3px #888888;
    background-color: var(--v-headerblue-base); border-radius: 16px
}
.appContainerList {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    padding-inline: 10px;
    cursor: pointer;
}
.listButton{
    display: flex;
    align-content: center;
    border-radius: 16px;
    margin-right: 1rem;
}
.listButtonImage{
    border-radius: 8px;
    height: 35px;
    width: 35px;
    padding: 6px !important;
}
.buttonImage50 {
    max-width: 48px;
    max-height: 48px;
}
.buttonImage {
    max-width: 78px;
    max-height: 78px;
}
.button100 {
    height: 100px;
    width: 100px;
}
.icon50 {
    height: 50px;
    width: 50px;
}
.button50 {
    height: 67px;
    width: 67px;
}
.badgeClass {
    width: 100%;
    height:100%;
    display:-moz-inline-box;
}

.internetSheet {
   display: flex;
    height: 100px;
    white-space: nowrap;
    justify-content: center;
    cursor: pointer;
    aspect-ratio: 1/1;
    margin-top:-36px;    
}
.internetSheet50 {
     display: flex;
    height: 67px;
    white-space: nowrap;
    justify-content: center;
    cursor: pointer;
    aspect-ratio: 1/1;
    margin-top:-36px;
}
.internetContainer {
    height: 100px;
    width: 100px;
    margin: 10px;
    margin-bottom: 45px;
    box-shadow: 0px 4px 6px -3px #888888;
    border-radius: 16px;
}
.internetContainer50 {
    height: 67px;
    width: 67px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 45px;
    border-radius: 16px;
    box-shadow: 0px 4px 6px -3px #888888;
}

.badgeEventVisualize {
    animation: badgeAppointmentRipple 1000ms ease-in-out;
}

@keyframes badgeAppointmentRipple {
    0% {
        background-color: rgba(52, 62, 85, 0.8);
    }
    10% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    75% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    100% {
        background-color: rgba(52, 62, 85, 0.8);
    }
}
.scroll-area {
    position: relative;
    max-height: calc(85vh - 80px);
    overflow-x: hidden;
    border-radius: 0 0 16px 16px;

}
.listDivider {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

}
</style>
